















































































.live-warmup {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 37px 20px 30px;
    color: #fff;
    .title {
        font-size: 16px;
        font-weight: 500;
    }
    .release {
        position: relative;
        width: 178px;
        margin: 20px 0;
        cursor: pointer;
        .img {
            width: 100%;
        }
        .label {
            position: absolute;
            top: 57px;
            left: 50%;
            transform: translate(-50%);
        }
    }
    .table {
        flex: 1;
        overflow: hidden;
        /deep/ .el-table {
            border: 1px solid #262963;
            background-color: transparent;
            &::before {
                background-color: #262963;
            }
            .el-table__header .has-gutter tr,
            .el-table__header .has-gutter th {
                background-color: #0c0e3f;
                height: 60px;
            }
            .el-table__header .has-gutter th {
                border-color: #262963;
            }

            .el-table__body .el-table__row {
                background-color: transparent;
                color: #fff;
                &:hover .el-table__cell{
                    background-color: transparent;
                }
                .el-table__cell {
                    border-color: #262963;
                    .nickname {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        .topic {
                            color: #fff;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    
}
